import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { SectionTilesProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Switch from '../elements/Switch';
import Button from '../elements/Button';

const propTypes = {
  ...SectionTilesProps.types,
  pricingSwitcher: PropTypes.bool,
};

const defaultProps = {
  ...SectionTilesProps.defaults,
  pricingSwitcher: false,
};

class Pricing extends React.Component {
  state = {
    pricingSwitchOn: true,
  };

  handlePricingSwitch = (e) => {
    this.setState({ pricingSwitchOn: e.target.checked });
  };

  render() {
    const {
      className,
      topOuterDivider,
      bottomOuterDivider,
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      pushLeft,
      pricingSwitcher,
      ...props
    } = this.props;

    const outerClasses = classNames(
      'pricing section',
      topOuterDivider && 'has-top-divider',
      bottomOuterDivider && 'has-bottom-divider',
      hasBgColor && 'has-bg-color',
      invertColor && 'invert-color',
      className
    );

    const innerClasses = classNames(
      'pricing-inner section-inner',
      topDivider && 'has-top-divider',
      bottomDivider && 'has-bottom-divider'
    );

    const tilesClasses = classNames('tiles-wrap', pushLeft && 'push-left');

    const sectionHeader = {
      title: 'Start deploying software securely',
      paragraph: '',
    };

    return (
      <section {...props} className={outerClasses}>
        <div className="container">
          <div className={innerClasses}>
            <SectionHeader
              data={sectionHeader}
              tag="h1"
              className="center-content reveal-from-bottom"
            />
            {pricingSwitcher && (
              <div className="pricing-switcher center-content reveal-from-top">
                <Switch
                  checked={this.state.pricingSwitchOn}
                  onChange={this.handlePricingSwitch}
                  rightLabel="Billed Annually"
                >
                  Billed Monthly
                </Switch>
              </div>
            )}
            <div className={tilesClasses}>
              <div
                className="tiles-item reveal-from-right"
                data-reveal-delay="400"
              >
                <div className="tiles-item-inner">
                  <div className="pricing-item-content">
                    <div className="pricing-item-header pb-24">
                      <div className="pricing-item-price mb-4">
                        <span className="pricing-item-price-amount h1">
                          Standard
                        </span>
                      </div>
                      <div className="text-xs text-color-low">
                        Great for most SMBs:
                      </div>
                    </div>
                    <div className="pricing-item-features mb-40">
                      <ul className="pricing-item-features-list list-reset text-xs mb-32">
                        <li className="is-checked">
                          AWS, GCP, and Azure deployments
                        </li>
                        <li className="is-checked">Push-button upgrades</li>
                        <li className="is-checked">
                          Deployment version history
                        </li>
                        <li className="is-checked">Support via email</li>
                      </ul>
                    </div>
                  </div>
                  <div className="pricing-item-cta mb-8">
                    <Button
                      tag="a"
                      color="primary"
                      wide
                      href="https://metamanagement.typeform.com/to/mBCDHj"
                      target="_blank"
                    >
                      Request Access
                    </Button>
                  </div>
                </div>
              </div>

              <div
                className="tiles-item reveal-from-left"
                data-reveal-delay="400"
              >
                <div className="tiles-item-inner">
                  <div className="pricing-item-content">
                    <div className="pricing-item-header pb-24">
                      <div className="pricing-item-price mb-4">
                        <span className="pricing-item-price-amount h1">
                          Custom
                        </span>
                      </div>
                      <div className="text-xs text-color-low">
                        Everything in standard, plus:
                      </div>
                    </div>
                    <div className="pricing-item-features mb-40">
                      <ul className="pricing-item-features-list list-reset text-xs mb-32">
                        <li className="blue-checked">
                          Full on-premise deployments
                        </li>
                        <li className="blue-checked">
                          Federated log management
                        </li>
                        <li className="blue-checked">White-label branding</li>
                        <li className="blue-checked">
                          Support via email &amp; Slack
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="pricing-item-cta mb-8">
                    <Button
                      tag="a"
                      color="secondary"
                      wide
                      href="https://metamanagement.typeform.com/to/dhVIcs"
                      target="_blank"
                    >
                      Contact Us
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

Pricing.propTypes = propTypes;
Pricing.defaultProps = defaultProps;

export default Pricing;
