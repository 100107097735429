import React from 'react';
// import sections
import HeroSplit from '../components/sections/HeroSplit';
import FeaturesTiles from '../components/sections/FeaturesTiles';
import Pricing from '../components/sections/Pricing';
import Cta from '../components/sections/Cta';
// import some required elements

class Home extends React.Component {
  state = {
    videoModalActive: false,
  };
  openModal = (e) => {
    e.preventDefault();
    this.setState({ videoModalActive: true });
  };

  closeModal = (e) => {
    e.preventDefault();
    this.setState({ videoModalActive: false });
  };

  render() {
    return (
      <React.Fragment>
        <HeroSplit className="illustration-section-01" />
        <Cta split />
        <FeaturesTiles topDivider className="center-content" />
        <Pricing topDivider />
      </React.Fragment>
    );
  }
}

export default Home;
