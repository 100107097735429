import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';

import { Input, message } from 'antd';

const propTypes = {
  ...SectionProps.types,
  split: PropTypes.bool,
};

const defaultProps = {
  ...SectionProps.defaults,
  split: false,
};

class Cta extends React.Component {
  render() {
    const {
      className,
      topOuterDivider,
      bottomOuterDivider,
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      split,
      ...props
    } = this.props;

    const outerClasses = classNames(
      'cta section center-content-mobile reveal-from-bottom',
      topOuterDivider && 'has-top-divider',
      bottomOuterDivider && 'has-bottom-divider',
      hasBgColor && 'has-bg-color',
      invertColor && 'invert-color',
      className
    );

    const innerClasses = classNames(
      'cta-inner section-inner',
      topDivider && 'has-top-divider',
      bottomDivider && 'has-bottom-divider',
      split && 'cta-split'
    );

    return (
      <section {...props} className={outerClasses}>
        <div className="container">
          <div className={innerClasses}>
            <div className="cta-slogan">
              <h3 className="m-0">
                Want to talk to someone at Metamanagement?
              </h3>
            </div>
            <Input.Search
              id="newsletter"
              type="email"
              placeholder="Your email"
              enterButton="Submit"
              size="large"
              onSearch={(email) => {
                sendHomepageInterestForm(email);
              }}
            />
          </div>
        </div>
      </section>
    );
  }
}

// Shamelessly taken from https://stackoverflow.com/questions/46155/how-to-validate-an-email-address-in-javascript
function validateEmail(email) {
  var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

// Generated using:
// curl -sL goo.gl/jUkahv | python2 - https://docs.google.com/forms/d/17UkO5q_smojTT-0EJqC196eEmY4OU9oXKQCKO4TUda4/edit
// See https://gist.github.com/davidbau/8c168b2720eacbf4e68e9e0a9f437838 for details
function sendHomepageInterestForm(email) {
  if (!validateEmail(email)) {
    message.error('Invalid email address entered.');
    return;
  }

  var formid = 'e/1FAIpQLSfdYP8Qza9j-kac0n8tgz1B_Av5u4_rKCxCSpL3hLPfofkERw';
  // Submit the form using an image to avoid CORS warnings.
  const tempImg = new Image();
  // tempImg.crossOrigin = 'anonymous';
  tempImg.referrerPolicy = 'no-referrer';
  tempImg.src =
    'https://docs.google.com/forms/d/' +
    formid +
    '/formResponse?entry.2064793338=' +
    email;

  message.success(
    'Your email has been submitted. Someone will be in touch soon!'
  );
}

Cta.propTypes = propTypes;
Cta.defaultProps = defaultProps;

export default Cta;
